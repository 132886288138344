<template>
  <div class="setting" v-if="!isProcessing">
    <div class="setting__menu" v-for="item in items" :key="item.title">
      <setting-menu :item="item" />
    </div>
  </div>
</template>

<script>
import SettingMenu from '@/components/common/menu.vue'

export default {
  name: 'setting',
  components: { SettingMenu },
  data () {
    return {
      items: [
        { title: 'ログアウト', content: 'ログアウトの実行', func: () => this.signout() }
      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * サインアウト
     */
    async signout () {
      this.$store.commit('setProcessing', false)
      await this.$store.dispatch('auth/signout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.setting {
  padding: $padding_height $padding_width;
  margin-top: $header_height;
}
</style>
